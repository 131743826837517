import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Pagination from 'react-js-pagination';
import { css } from '@emotion/core';
import moment from 'moment';
import Header from '../../../components/common/Header';

import DownloadIcon from '../../../helpers/icons/download';
import styles from './styles';
import SubHeader from '../../../components/common/SubHeader';
import { Link } from '../../../components/common/Misc';

const linkStyles = css`
  box-shadow: none;
`;

const BoletinTemplate = (props) => {
    const itemsCountPerPage = 9;
    const [page, setPage] = useState(1);
    const { data: { strapiGrupoMesResumenRegulatorios: grReleases } } = props;
    const ordered = grReleases.resumenes_regulatorios.sort((a, b) => moment(a.publish_date.valueOf()).diff(b.publish_date));
    const onClickLink = (e) => {
        e.preventDefault();
        window.history.back();
    };

    return (
        <div css={styles}>
            <Header section="resumenes-regulatorios" />
            <SubHeader title={`Resúmenes Regulatorios ${grReleases.month}`} />
            <div className="back-to">
                <a // eslint-disable-line
                    href="#"
                    onClick={onClickLink}
                    rel="nofollow noopener noreferrer"
                >
                    &larr; Atrás
                </a>
            </div>
            <div className="releases">
                {grReleases.resumenes_regulatorios
                    .map((post, i) => (
                        <div
                            key={post.id}
                            className="release"
                        >
                            <Link
                                to={post.url}
                                className={linkStyles}
                                rel="nofollow noopener noreferrer"
                                target="_blank"
                            >
                                <h4>{post.title}</h4>
                                <h6>Abrir</h6>
                            </Link>
                        </div>
                    ))}
            </div>
        </div>
    );
};

BoletinTemplate.propTypes = {
    data: PropTypes.object.isRequired
};

export const pageQuery = graphql`
    query NewsletterByPath($slug: String) {
        strapiGrupoMesResumenRegulatorios(slug: { eq: $slug }) {
            resumenes_regulatorios {
              publish_date
              title
              url
            }
            strapiId
            title
            slug
            month
        }
    }
`;

export default BoletinTemplate;
